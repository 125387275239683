<template>
  <div class="ele-body">
    <el-card shadow="never">
      <el-form :model="table.where" class="ele-form-search d-flexspabet"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <div style="margin-bottom: 10px">
          <el-button @click="(showEdit=true) && (showEditData = false)" size="small" class="ele-btn-icon addbtn ">添加服务商
          </el-button>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="service" label="服务商" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="url" label="接口地址" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="link" label="官网" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="secret" label="密钥" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="create_time" label="添加时间" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="side_type" label="服务状态" show-overflow-tooltip width="140px">
            <template slot-scope="scope">
              {{scope.row.status==1?'服务中':'未服务'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
                       v-if="permission.includes('sys:handling:edit')">编辑
              </el-link>
              <el-link @click="change(row)" icon="el-icon-view" type="primary" :underline="false" v-if="row.status == 0 && permission.includes('sys:handling:change')">切换此服务商</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'查看服务商':'添加服务商'" :visible.sync="showEdit"
               @closed="[editForm={}]"
               :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">

        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">

          <el-form-item label="服务商名称：" prop="service" label-width='160px'>
            <el-input v-model="editForm.service" placeholder="请输入服务商名称" clearable class="w-400"/>
          </el-form-item>
          <el-form-item label="接口地址：" prop="url" label-width='160px'>
            <el-input v-model="editForm.url" placeholder="请输入接口地址" clearable class="w-400"/>
          </el-form-item>
          <el-form-item label="接口密钥：" prop="secret" label-width='160px'>
            <el-input v-model="editForm.secret" placeholder="请输入接口密钥" clearable class="w-400"/>
          </el-form-item>
          <el-form-item label="官网：" prop="link" label-width='160px'>
            <el-input v-model="editForm.link" placeholder="请输入官网地址" clearable class="w-400"/>
          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'SysDownload',
  data() {
    return {
      table: {
        url: '/push_config/index',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {}, // 表单数据
      editRules: { // 表单验证规则
        service: [{
          required: true,
          message: '请输入服务商名称',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请输入接口地址',
          trigger: 'blur'
        }],
        secret: [{
          required: true,
          message: '请输入接口密钥',
          trigger: 'blur'
        }],
        link: [{
          required: true,
          message: '请输入官网地址',
          trigger: 'blur'
        }],
      },
      userInfo: {},
      header: {
        'Content_type': 'multipart/form-data',
      },
      file: [],
      formData: [],
      android: '',
      is_upload: false,
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['permission']),
  },
  mounted() {
  },
  methods: {
    // 限制文件类型
    beforeUpload(file) {
      this.is_upload = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['apk']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传的文件只能是 .apk 格式!')
        return false
      }
    },
    handleChange(file, fileList) {
      this.is_upload = true
      this.fileList = fileList.slice(-3)
    },
    handleAvatarSuccess(response, file, fileList) {
      this.android = response
      this.editForm.android = response
    },
    /**
     * 显示编辑
     */
    edit(row) {

          this.editForm = row
          this.showEdit = true
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          this.$http.post('/push_config/edit', this.editForm).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.showEdit = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        } else {
          return false
        }
      })
    },
    change(row) {

      this.$confirm('确定切换此服务商吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/push_config/change', {
          id: row.id
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })

    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据')
        let ids = this.choose.map(d => d.id)
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: ids
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      } else { // 单个删除
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: row.id
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload,
.ele-block >>> .el-upload-dragger {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 30px !important;
}
</style>
